<template>
  <div>
    <section v-if="!pageReload">
        <pm-Breadcrumb :home="home" :model="items_bread" class="hidden-mobile" />
        <div class="hide-mob">
          <p style="text-align: center; font-size: 15px;" ><b v-for="b in items_bread">{{ b.label }} &nbsp</b></p>
        </div>
        <br>
        <div class="p-fluid formgrid grid">
            <div class="field col-9 md:col-10">
              <h2 class="titulo-links-escolha exibe-mobile-msg2">
                Notas
                <va-popover
                  placement="right"
                  color="primary"
                  title="Informação do módulo atual"
                  message="Aqui o professor poderá lançar as notas dos alunos!"
                >
                  <i class="pi pi-question-circle" style="font-size: 1.5rem"></i>
                </va-popover>
              </h2>
              <h2 class="titulo-links-escolha exibe-mobile-msg"  style="margin-top: 7px;">
                Notas
              </h2>
            </div>
            <div class="field col-3 md:col-2" >
              <pm-Button type="button" style="float: right;margin-top: 5px;"
                @click="trocarTurma()" label="Trocar de Turma"
                class="p-button-danger trocar-turma" icon="pi pi-angle-left" iconPos="left"
              >
              </pm-Button>
            </div>
      </div>
      <p class="exibe-mobile-msg"> Aqui o professor poderá lançar as notas dos alunos!</p>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <selecionarDisciplinasProfessor @Config="Config"  :disciplina="disciplina" :infoVerificarPresenca="infoVerificarPresenca" />
        </div>
        <div class="field col-12 md:col-10" v-if="acesso.visualizar">
          <div class="card card-padd" v-if="disciplinaSelecionadaPeloProf !== null && isLoading == false">

            <h2 class="titulo-links-escolha2"  v-if="disciplinaSelecionadaPeloProf !== null && isLoading == false">Notas da disciplina {{disciplinaSelecionadaPeloProf.disciplina}}</h2>
            <div class="row" v-if="disciplinaUma != ''">
              <div class="col-md-6 ">

              </div>
              <div class="col-md-6 legenda-notas" style="display: contents;padding: 15px; float: right;">
                <p class="legenda-notas-p" >LEGENDA DAS CELULAS: </p>
                <div style="width:20px;height:20px;background-color:#5f9e8e38;margin-right: 10px;"></div>
                <p style="padding-right: 10px;padding-right: 16px;">CADASTRAR</p>
                <div style="width:20px;height:20px;background-color:rgb(224 214 216);margin-right: 10px;"></div>
                <p style="padding-right: 10px;padding-right: 16px;">EDITAR</p>
                <!--<p  style="padding-right: 10px;padding-right: 16px;">MÉDIA PARA APROVAÇÃO: <strong>{{mediaGeral}}</strong></p>-->
              </div>
              <div class="col-md-6 ">

              </div>

            <!--Fundamental 1-->
              <div v-if="tipoTabela == 1">
                <NotasFundamentalUmOuERN
                    :periodoSelecionado="periodoSelecionado"
                    :acesso="acesso"
                    :encerrado="encerrado"
                    :displayedRecords="displayedRecords"
                    @ajustarInfo="ajustarInfo"
                    @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
                    @adicionarEditarSimulado="adicionarEditarSimulado"
                    @adicionarEditarAtividade="adicionarEditarAtividade"
                    @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
                    @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
                    @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
                />
              </div>
              <!-- Fundamel 2 -->
              <div v-if="tipoTabela == 2">
                <NotasFundamentalDois
                    :periodoSelecionado="periodoSelecionado"
                    :acesso="acesso"
                    :encerrado="encerrado"
                    :displayedRecords="displayedRecords"
                    @ajustarInfo="ajustarInfo"
                    @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
                    @adicionarEditarSimulado="adicionarEditarSimulado"
                    @adicionarEditarAtividade="adicionarEditarAtividade"
                    @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
                    @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
                    @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
                />
              </div>
              <!-- Eja 1 -->
              <div v-if="tipoTabela == 3 && estiloTabelaEJA == 0">
                <NotasEjaFundamentalUm
                  :tipoTabela="tipoTabela"
                  :displayedRecords="displayedRecords"
                  :acesso="acesso"
                  :encerrado="encerrado"
                  @ajustarInfo="ajustarInfo"
                  @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
                  @adicionarEditarSimulado="adicionarEditarSimulado"
                  @adicionarEditarAtividade="adicionarEditarAtividade"
                  @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
                  @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
                  @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
                  :notaconfigperiodo="notaconfigperiodo"
                  :estiloTabelaEJA ="0"/>
              </div>
              <!--EJA 2 -->
              <div v-if="tipoTabela == 3 && estiloTabelaEJA == 1">
                <NotasEjaFundamentalUm
                  :tipoTabela="tipoTabela"
                  :displayedRecords="displayedRecords"
                  :acesso="acesso"
                  :encerrado="encerrado"
                  @ajustarInfo="ajustarInfo"
                  @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
                  @adicionarEditarSimulado="adicionarEditarSimulado"
                  @adicionarEditarAtividade="adicionarEditarAtividade"
                  @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
                  @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
                  @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
                  :notaconfigperiodo="notaconfigperiodo"
                  :estiloTabelaEJA ="1"/>
              </div>


              <questionario-nee
                :acesso="acesso"
                :formAlunoComNEE="formAlunoComNEE"
                :showModal="showModal"
                @verificarEditarNee="verificarEditarNee"
                :paraEditarNEE="paraEditarNEE"
                @alunocomNEE="alunocomNEE"
              />



            </div>

          </div>
        </div>
      </div>
    </section>
    <section v-if="pageReload">
      <div class="col-md-12" style="text-align: center;padding-top: 255px;">
            <pm-ProgressSpinner />
      </div>
    </section>
  </div>
</template>

<script>

import selecionarDisciplinasProfessor from '@/components/selecionarDisciplinasProfessor.vue'

import axios from 'axios';
import { DisciplinaEscolar } from "@/class/disciplina";
import { CalcularNota } from "@/class/calcularNotas";

import { defineComponent } from 'vue';
import { Escola } from "@/class/escolas";
import { Verificar } from "@/class/verificar.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Nota } from "@/class/nota";
import { Nee } from "@/class/nee";
import { SeriesEscolar } from "@/class/serie";
import { LiberacaoDiario } from "@/class/LiberacaoDiario.js";

import Pagination from 'v-pagination-3';
import { Configuracao } from '@/class/configuracao';

import NotasEjaFundamentalDois from '@/components/notas/notasEjaFundamentalDois.vue';
import NotasEjaFundamentalUm from '@/components/notas/notasEjaFundamentalUm.vue';
import NotasFundamentalDois from '@/components/notas/notasFundamentalDois.vue';
import NotasFundamentalUmOuERN from '@/components/notas/notasFundamentalUmOuERN.vue';

import questionarioNee  from '@/components/notas/questionarioNee.vue'

export default defineComponent({
  props: {
    id:{
      required: true
    },
    turma_id:{
      required: true
    },
    professor_id:{
      required: true
    },
    turma_disciplina_id:{
      required: true
    },
    segmento:{
      required: true
    },
    escola_id:{
      required:true,
    },
    serie_id:{
      required:true,
    },
    turno:{
      required:true,
    }
  },
  components: {
    Pagination,
    selecionarDisciplinasProfessor,

    NotasEjaFundamentalDois,
    NotasEjaFundamentalUm,
    NotasFundamentalDois,
    NotasFundamentalUmOuERN,

    questionarioNee
  },
  data () {
    const columns = [
      { key: "aluno", label: "Aluno", sortable: true },
      { key: "disciplina", label: "Disciplina", sortable: true },
      { key: "periodo_1", label: "1° Periodo"},
      { key: "periodo_2", label: "2° Periodo"},
      { key: "periodo_3", label: "3° Periodo"},
      { key: "periodo_4", label: "4° Periodo"},
      { key: "recuperacao_final", label: "Recuperacao Final"},
      { key: "passou", label: "Passou"},
    ];
    return {
      home: {icon: 'pi pi-home', to: '/ambiente-professor/turmas-professor'},
      items_bread: [
        {label: 'Notas do Aluno'},
      ],
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      info: {
        escola_id:null,
        segmento_id:null,
        serie_id:null,
        turma_id:null,
        disciplina_id:null
      },
      pageReload:false,
      notas:[],
      columns,
      escola:null,
      escolas:[],
      turma:null,
      turmas: [],

      escola_id: "",
      segmentoInfo: "",
      serieInfo: "",
      turma_id: null,

      segmentoALL: [],
      serieALL: [],
      turmaALL: [],
      alunoALL:[],
      encerrado:[],
      p1:false,
      p2:false,
      p3:false,
      p4:false,
      acesso:{
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      },

      paraEditarNEE:0,
      records:[],
      isLoadingPage:false,
      isLoading:false,
      tipoTabela:'',
      estiloTabelaEJA:'',
      anoAtual : '',
      mediaGeral : '',
      simulado : '',
      atividades : '',
      avaliacao : '',
      formAlunoComNEE: {
        aluno_id:'',
        nota_config_id:'',
        tipo:'',
        periodo:'',
        ano:'',
        suporte:'',
        compreensao:'',
        metodologia_utilizada:'',
        diagnostico_pedagogico:'',
        observacao:'',
      },
      showModal:false,
      notaconfigperiodo:'',
      periodoSelecionado:0,
      disciplinaALL:[],
      page: 1,
      perPage: 100,
      displayMaximizableEdit2:false,
      listaAutorizacaoALL:[],
      tipoDeAcao:-1,
      infoAutorizacao: {
        turma_id:-1,
        modulo:"notas",
        servidor_id:-1,
        qdt_dias:2,
      },
      displayMaximizable1:false,
      listPeriodos:[],
      professoresALL:[],
      configuracao:'',

      tipoTabela:0,
      tipoEja:0,

      mediaGeral: 0,
      estiloTabelaEJA:0,
      st_id: null,
      st_turma_id: null,
      st_professor_id: null,
      st_turma_disciplina_id:null,
      st_segmento:null,
      st_escola_id:null,
      st_serie_id:null,
      st_turno:null,
      realizarEdicaoAtividadeComplementar:0,
      realizarEdicaoAvaliacao:0,
      listaAvaliacaoAluno:[],
      listaAtividadesAluno:[],
      anoAtual:null,
      aluno_id_selecionado:0,

      avaliacao: {
        nota:0,
      },
      atividadeComplementar: {
        nome:'',
        nota:'',
        simulado:'',
      },
      turma_dados:[],
      notaParaSerAprovado:60,
      isLoading:false,
      disciplinaSelecionadaPeloProf: null,
      disciplinaUma:'',
      disciplina:[],
      aluno:null,
      alunos: [],

      records: [],
      itensNotas: 1,
      infoVerificarPresenca: {
          disciplina_id:null,
      },

      simulado: 20,
      atividades: 30,
      avaliacao: 50,

      periodoLiberado : {
        p1:false,
        p2:false,
        p3:false,
        p4:false
      }
    }
  },
  computed: {

    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      if(endIndex != 'undefined'){
        return this.records.slice(startIndex, endIndex);
      }
    }
  },
  async beforeMount() {
    this.pageReload = true;
    let acesso = await Verificar.AcessoLocal(13);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;

    if(this.id == null ||  this.turma_id == null){
        this.st_id = sessionStorage.getItem("Professor_id");
        this.st_turma_id = sessionStorage.getItem("Professor_turma_id");
        this.st_professor_id = sessionStorage.getItem("Professor_professor_id");
        this.st_turma_disciplina_id = sessionStorage.getItem("Professor_turma_disciplina_id");
        this.st_segmento = sessionStorage.getItem("Professor_segmento");
        this.st_escola_id = sessionStorage.getItem("Professor_escola_id");
        this.st_serie_id = sessionStorage.getItem("Professor_serie_id");
        this.st_turno = sessionStorage.getItem("Professor_turno");
    }else{
      this.st_id = this.id;
      this.st_turma_id= this.turma_id;
      this.st_professor_id = this.professor_id;
      this.st_turma_disciplina_id =this.turma_disciplina_id;
      this.st_segmento =this.segmento;
      this.st_escola_id =this.escola_id;
      this.st_serie_id =this.serie_id;
      this.st_turno =this.turno;
    }

    this.info.segmento_id = this.st_segmento;
    this.info.serie_id = this.st_serie_id;

    await this.buscarTipoDeTabela();
    await this.buscarDisciplinas();
    await this.listarEscolas();
    await this.visuPeriodosEncerrados();

    this.anoAtual = parseInt(sessionStorage.getItem("anoSelecionado"));

    let turma = {
      turma_id: this.st_turma_id,
      etapa_id: this.st_segmento,
      periodo_id: this.st_serie_id,
      escola_id: this.st_escola_id,
    }
    let resp_turma = await Turma.aturma(turma);
    let  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
    ];


    this.items_bread = tt;
    this.turma_dados = resp_turma.data;

    await this.verificarLibercaoDePeriodo();
    await this.buscarConfiguracoes();
    this.pageReload = false;
  },
  methods:{
    async buscarConfiguracoes() {
      let eja2 = 0;
      if (this.tipoTabela == 3 && this.estiloTabelaEJA == 1)
        eja2 = 1;

      let dt = await SegmentoEscolar.obtemUm(this.st_segmento);
      let data2 = await Configuracao.buscarConfiguracaoComTipo(dt.data.tipo, eja2);

      this.configuracao = data2.data;
    },
    async mudarParaRetidoOuDependencia(record, tipo) {
      if (tipo == 1) {
        if (window.confirm("Você realmente deseja alterar de RETIDO para PROGRESSÃO PARCIAL?")) {
          let novo = {
            id: record.aluno_notas_completa.id,
          }
          let data = await Nota.alterarRetidoDependencia(novo);
          this.listarNotas();
        }
      }
      else if (tipo == 2) {
        if (window.confirm("Você realmente deseja alterar de PROGRESSÃO PARCIAL para RETIDO?")) {
          let novo = {
            id: record.aluno_notas_completa.id,
          }
          let data = await Nota.alterarRetidoDependencia(novo);
          this.listarNotas();
        }
      }
    },
    verificarSePeriodoJaTaEmAberto() {
        let periNumero = parseInt(this.infoAutorizacao.periodo_semestre[0]);
        let podeCadastrar = true;
        if (!this.encerrado.p1 && periNumero == 1) {
          podeCadastrar = false;
        }
        else if (!this.encerrado.p2 && periNumero == 2) {
          podeCadastrar = false;

        }
        else if (!this.encerrado.p3 && periNumero == 3) {
          podeCadastrar = false;

        }
        else if (!this.encerrado.p4 && periNumero == 4) {
          podeCadastrar = false;

        }
        if (podeCadastrar) {
          return true;
        }
        else {
          this.displayMaximizable1 = false;
          this.$vaToast.init({
              message: "O período selecione já está aberto!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color: 'warning' ,
              duration: 3000,
              fullWidth: false,
          })
          return false;
        }

    },
    async  verificarLibercaoDePeriodo() {
      let novo = {
        modulo : "notas",
        turma_id : this.st_turma_id,
        servidor_id : this.st_professor_id
      }
      let data = await LiberacaoDiario.professorVerificarLiberacao(novo);

      //ele ta liberado para mexer no periodo 1
      this.periodoLiberado.p1 = data.data.find(element => element.periodo_semestre === "1º Período") != undefined;
      //ele ta liberado para mexer no periodo 2
      this.periodoLiberado.p2 = data.data.find(element => element.periodo_semestre === "2º Período") != undefined;
      //ele ta liberado para mexer no periodo 3
      this.periodoLiberado.p3 = data.data.find(element => element.periodo_semestre === "3º Período") != undefined;
      //ele ta liberado para mexer no periodo 4
      this.periodoLiberado.p4 = data.data.find(element => element.periodo_semestre === "4º Período") != undefined;

    },
    async apagarAutorizacao(data) {
        try {
          if (window.confirm("Você realmente deseja apagar a autorização selecionada?")) {
            try {
              let data1 = await LiberacaoDiario.deletarAutorizacao(data.id);
              this.$vaToast.init({
                message: "Removido com sucesso!",
                iconClass: 'fa-star-o',
                position: 'top-right',
                color: 'success' ,
                duration: 2500,
                fullWidth: false,
              })
              this.listarAuto();
            } catch (e) {
              let a = e.response.data.erro;
                setTimeout(() => {
                  this.$vaToast.init({
                    message: a,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3500,
                    fullWidth: false,
                    color: 'danger'
                  });
                }, 500);
            }
          }
        }
        catch(e) {
        }
    },
    async editarAutorizacao() {
      try {
        this.infoAutorizacao.servidor_id = this.infoAutorizacao.servidor_id.id;
        this.infoAutorizacao.periodo_semestre = this.infoAutorizacao.periodo;

        //verificar se ja ta aberto
        if(!this.verificarSePeriodoJaTaEmAberto())
          return false;

        let data = await LiberacaoDiario.editarLiberacao(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          //this.msg_erro = e.response.data;
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }

    },
    async editarAutori(data) {
      this.tipoDeAcao = 2;
      this.buscarPeriodosDisponi();
      this.infoAutorizacao.id = data.id;
      this.infoAutorizacao.turma_id = data.turma_id;
      this.infoAutorizacao.servidor_id = this.professoresALL.find(element => element.id == data.servidor_id);

      this.infoAutorizacao.periodo = data.periodo_semestre;
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    async salvarAutorizacao() {
      this.infoAutorizacao.turma_id = this.turma_id;
      this.infoAutorizacao.servidor_id =   this.infoAutorizacao.servidor_id.id;
      this.infoAutorizacao.periodo_semestre = this.infoAutorizacao.periodo;
      this.infoAutorizacao.mes_liberado = "";
      //verificar se ja ta aberto
      if(!this.verificarSePeriodoJaTaEmAberto())
        return false;
      try {
        let data = await LiberacaoDiario.CadastrarDiario(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Salvo com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          //this.msg_erro = e.response.data;
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }
    },

    async buscarProfessores() {
      let data = await Turma.buscarProfessoresDaTurma(this.turma_id);
      this.professoresALL = [];
      for (const el of data.data) {
        el.nome_todo = el.nome + ' ' +  el.sobrenome;
        this.professoresALL.push(el);
      }
    },
    limparCamposAutorizacao() {
      this.infoAutorizacao.turma_id = null;
      this.infoAutorizacao.servidor_id =  '';
      this.infoAutorizacao.periodo_semestre = "";
    },
    buscarPeriodosDisponi() {
      this.listPeriodos = [];
      this.listPeriodos.push("1º Período");
      this.listPeriodos.push("2º Período");
      this.listPeriodos.push("3º Período");
      this.listPeriodos.push("4º Período");
    },
    cadastrarInfo() {
      //cadastar
      this.limparCamposAutorizacao();
      this.tipoDeAcao = 1;
      this.listPeriodos = [];
      //liberar apenas peridos que estao fechados
      this.buscarPeriodosDisponi();
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    async listarAuto() {
      let data = await LiberacaoDiario.visualizarLiberacao("notas");
      this.listaAutorizacaoALL = [];
      for (const el of data.data) {
        el.nome = el.servidor.nome + ' ' + el.servidor.sobrenome;
        el.periodo = el.periodo_semestre;
        this.listaAutorizacaoALL.push(el);
      }
    },
    async openModalAutorizacao() {
      this.listarAuto();
      //buscar lista de autorizaçã
      this.displayMaximizableEdit2 = !this.displayMaximizableEdit2;
    },
    ajustarInfo(aluno_id) {
      this.formAlunoComNEE.ano = this.anoAtual;
      this.formAlunoComNEE.aluno_id = aluno_id;
      this.formAlunoComNEE.tipo = '';
      this.formAlunoComNEE.suporte = '';
      this.formAlunoComNEE.compreensao = '';
      this.formAlunoComNEE.metodologia_utilizada = '';
      this.formAlunoComNEE.diagnostico_pedagogico = '';
      this.formAlunoComNEE.observacao = '';
      this.showModal = !this.showModal;
    },
    async listarNotas() {
      try {
        this.isLoadingPage = true;
        let anoSelect = sessionStorage.getItem("anoSelecionado");
        let data = await Nota.ListarNotaAlunos(this.st_turma_id,this.disciplinaUma["disciplina_id"],this.anoAtual);
        this.records = data.data;

        if (!this.records.length) {
          this.$vaToast.init({
            message: "A turma não possue alunos!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color:'warning',
            duration: 3000,
            fullWidth: false,
          });
          this.isLoadingPage = false;

          return false;
        }

        //Bimestral
        if (this.configuracao.info.notas.recuperacao_bimestral) {
          for (var i = 0; i < this.records.length; i++) {
            //Eh um que nao esta na turma
            if (this.records[i].aluno_fora_da_turma == true)
              continue;

            //verificar se existe recuperacao
            if (this.records[i].aluno_notas_completa) {
              if (this.records[i].aluno_notas_completa.recuperacao_final != null) {
                this.records[i].temRecuperacao = 1;
              }
              else {
                this.records[i].temRecuperacao = 0;
              }
            }
            //Primeiro periodo
            if (this.records[i].periodo1_atividades != null) {
              this.records[i].ativPer1 = this.records[i].periodo1_atividades[0].atividade;
            }
            else {
              this.records[i].ativPer1 = '';
            }
            if (this.records[i].periodo1_avaliacao != null) {
              this.records[i].avaliacaoPer1 = this.records[i].periodo1_avaliacao.avaliacao;
            }
            else {
              this.records[i].avaliacaoPer1 = '';
            }
            if (this.records[i].periodo1_simulado != null) {
              this.records[i].simuladoPer1 = this.records[i].periodo1_simulado.simulado;
            }
            else {
              this.records[i].simuladoPer1 = '';
            }
            if (this.records[i].periodo1_recuperacao != null) {
              this.records[i].recuperacaoPer1 = this.records[i].periodo1_recuperacao.recuperacao;
            }
            else {
              this.records[i].recuperacaoPer1 = '';
            }
            this.records[i].total1 = 0;
            if (!this.estiloTabelaEJA) {
              if (this.records[i].ativPer1 != '')
                this.records[i].total1 = this.records[i].ativPer1;
              if (this.records[i].simuladoPer1 != '')
                this.records[i].total1 += this.records[i].simuladoPer1;
              if (this.records[i].avaliacaoPer1 != '')
                this.records[i].total1 += this.records[i].avaliacaoPer1;
            }

            this.records[i].media1 = await CalcularNota.calcMediaBimestral(this.records[i], 1,  this.configuracao.info.notas.notas_substituir_recup, this.configuracao);

            //Segundo periodo
            if (this.records[i].periodo2_atividades != null) {
              this.records[i].ativPer2 = this.records[i].periodo2_atividades[0].atividade;
            }
            else {
              this.records[i].ativPer2 = '';
            }
            if (this.records[i].periodo2_avaliacao != null) {
              this.records[i].avaliacaoPer2 = this.records[i].periodo2_avaliacao.avaliacao;
            }
            else {
              this.records[i].avaliacaoPer2 = '';
            }

            if (this.records[i].periodo2_simulado != null) {

              this.records[i].simuladoPer2 = this.records[i].periodo2_simulado.simulado;
            }
            else {
              this.records[i].simuladoPer2 = '';
            }
            if (this.records[i].periodo2_recuperacao != null) {
              this.records[i].recuperacaoPer2 = this.records[i].periodo2_recuperacao.recuperacao;
            }
            else {
              this.records[i].recuperacaoPer2 = '';
            }
            this.records[i].total2 = 0;
            if (!this.estiloTabelaEJA) {
              if (this.records[i].ativPer2 != '')
                this.records[i].total2 = parseInt(this.records[i].ativPer2);
              if (this.records[i].simuladoPer2 != '')
                this.records[i].total2 += parseInt(this.records[i].simuladoPer2);
              if (this.records[i].avaliacaoPer2 != '')
                this.records[i].total2 += parseInt(this.records[i].avaliacaoPer2);
            }




            this.records[i].media2 = await CalcularNota.calcMediaBimestral(this.records[i], 2, this.configuracao.info.notas.notas_substituir_recup, this.configuracao);
            if (this.tipoTabela != 3) {
              //Terceiro periodo
              if (this.records[i].periodo3_atividades != null) {
                this.records[i].ativPer3 = this.records[i].periodo3_atividades[0].atividade;
              }
              else {
                this.records[i].ativPer3 = '';
              }
              if (this.records[i].periodo3_avaliacao != null) {
                this.records[i].avaliacaoPer3 = this.records[i].periodo3_avaliacao.avaliacao;
              }
              else {
                this.records[i].avaliacaoPer3 = '';
              }
              if (this.records[i].periodo3_simulado != null) {
                this.records[i].simuladoPer3 = this.records[i].periodo3_simulado.simulado;
              }
              else {
                this.records[i].simuladoPer3 = '';
              }
              if (this.records[i].periodo3_recuperacao != null) {
                this.records[i].recuperacaoPer3 = this.records[i].periodo3_recuperacao.recuperacao;
              }
              else {
                this.records[i].recuperacaoPer3 = '';
              }
              this.records[i].media3 = await CalcularNota.calcMediaBimestral(this.records[i], 3, this.configuracao.info.notas.notas_substituir_recup, this.configuracao);
              //Quarto periodo
              if (this.records[i].periodo4_atividades != null) {
                this.records[i].ativPer4 = this.records[i].periodo4_atividades[0].atividade;
              }
              else {
                this.records[i].ativPer4 = '';
              }
              if (this.records[i].periodo4_avaliacao != null) {
                this.records[i].avaliacaoPer4 = this.records[i].periodo4_avaliacao.avaliacao;
              }
              else {
                this.records[i].avaliacaoPer4 = '';
              }
              if (this.records[i].periodo4_simulado != null) {
                this.records[i].simuladoPer4 = this.records[i].periodo4_simulado.simulado;
              }
              else {
                this.records[i].simuladoPer4 = '';
              }
              if (this.records[i].periodo4_recuperacao != null) {
                this.records[i].recuperacaoPer4 = this.records[i].periodo4_recuperacao.recuperacao;
              }
              else {
                this.records[i].recuperacaoPer4 = '';
              }
              this.records[i].media4 = await CalcularNota.calcMediaBimestral(this.records[i], 4, this.configuracao.info.notas.notas_substituir_recup, this.configuracao);
              this.records[i].total = this.records[i].media1 + this.records[i].media2 + this.records[i].media3 + this.records[i].media4;
            }
            else {
              this.records[i].total = this.records[i].media1 + this.records[i].media2;
            }
            this.records[i].mediaFinal = await CalcularNota.caclMediaFinalTipoBimestral(this.records[i].total, this.records[i], this.tipoTabela);
            if (this.records[i].aluno_notas_completa_existe) {
              this.records[i].recuperacaoFinal =  this.records[i].aluno_notas_completa.recuperacao_final;
            }
            else {
              this.records[i].recuperacaoFinal  = '';
            }
          }
          let notaCompleta = [];
          //Sempre atualizar a nota completa
          for (var i = 0; i < this.records.length; i++) {
             //Eh um que nao esta na turma
            if (this.records[i].aluno_fora_da_turma == true) {

              continue;
            }
            let novo = {
              aluno_notas_id:  this.records[i].aluno_notas_id,
              disciplina_id:  this.records[i].disciplina_id,
              periodo_1:  String(Math.max(this.records[i].media1, this.records[i].recuperacaoPer1)),
              periodo_2:  String(Math.max(this.records[i].media2, this.records[i].recuperacaoPer2)),
              periodo_3:  String(Math.max(this.records[i].media3, this.records[i].recuperacaoPer3)),
              periodo_4:  String(Math.max(this.records[i].media4, this.records[i].recuperacaoPer4)),
              recuperacao_final: this.records[i].recuperacaoFinal,
              media_final: String(this.records[i].mediaFinal),
              passou: this.records[i].mediaFinal >= this.mediaGeral,
            }
            if (novo.passou == 1) {
              this.records[i].passou = 1;
              novo.passou = 1;
              novo.dependencia = 0;
              //ok
            }
            else if (this.records[i].aluno_notas_completa.dependencia) {
              //nao alterar
              this.records[i].passou = 2;
              novo.passou = 2;
              novo.dependencia = 1;

            }
            else if (!this.records[i].aluno_notas_completa.dependencia) {
              this.records[i].passou = 0;
              novo.passou = 0;
              novo.dependencia = 0;
            }
            notaCompleta.push(novo);
          }
          let data1 = '';
          if (notaCompleta.length)
            data1 = await Nota.adicionarDeTodosAlunos(notaCompleta);
        }
        else if (!this.configuracao.info.notas.recuperacao_bimestral) {
          for (var i = 0; i < this.records.length; i++) {
            //Eh um que nao esta na turma
            if (this.records[i].aluno_fora_da_turma === true)
              continue;
            //verificar se existe recuperacao
            if (this.records[i].aluno_notas_completa) {
              if (this.records[i].aluno_notas_completa.recuperacao_final != null) {
                this.records[i].temRecuperacao = 1;
              }
              else {
                this.records[i].temRecuperacao = 0;
              }
            }
            //Primeiro periodo
            if (this.records[i].periodo1_atividades != null) {
              this.records[i].ativPer1 = this.records[i].periodo1_atividades[0].atividade;
            }
            else {
              this.records[i].ativPer1 = '';
            }
            if (this.records[i].periodo1_avaliacao != null) {
              this.records[i].avaliacaoPer1 = this.records[i].periodo1_avaliacao.avaliacao;
            }
            else {
              this.records[i].avaliacaoPer1 = '';
            }
            if (this.records[i].periodo1_simulado != null) {
              this.records[i].simuladoPer1 = this.records[i].periodo1_simulado.simulado;
            }
            else {
              this.records[i].simuladoPer1 = '';
            }
            this.records[i].media1 = await CalcularNota.calcTotalPeriodoSemestral(this.records[i], 1,this.configuracao);
            //Segundo periodo
            if (this.records[i].periodo2_atividades != null) {
              this.records[i].ativPer2 = this.records[i].periodo2_atividades[0].atividade;
            }
            else {
              this.records[i].ativPer2 = '';
            }
            if (this.records[i].periodo2_avaliacao != null) {
              this.records[i].avaliacaoPer2 = this.records[i].periodo2_avaliacao.avaliacao;
            }
            else {
              this.records[i].avaliacaoPer2 = '';
            }
            if (this.records[i].periodo2_simulado != null) {
              this.records[i].simuladoPer2 = this.records[i].periodo2_simulado.simulado;
            }
            else {
              this.records[i].simuladoPer2 = '';
            }
            if (this.records[i].periodo2_recuperacao != null) {
              this.records[i].recuperacaoSemestre1 = this.records[i].periodo2_recuperacao.recuperacao;
            }
            else {
              this.records[i].recuperacaoSemestre1 = '';
            }
            this.records[i].media2 = await CalcularNota.calcTotalPeriodoSemestral(this.records[i], 2,this.configuracao);


            this.records[i].periodo1Nota =  this.records[i].media1;
            this.records[i].periodo2Nota =  this.records[i].media2;

            if (this.records[i].recuperacaoSemestre1 != '')  {
              var answer = await CalcularNota.aRecuperacaoSubstituiOQue(this.records[i].periodo1Nota, this.records[i].periodo2Nota, this.records[i].recuperacaoSemestre1);
              if (answer == 1) {
                this.records[i].periodo1Nota  =  this.records[i].recuperacaoSemestre1;
                this.records[i].periodo2Nota  =  this.records[i].recuperacaoSemestre1;
              }
              else if (answer == 2) {
                this.records[i].periodo1Nota =   this.records[i].recuperacaoSemestre1;
              }
              else if (answer == 3) {
                this.records[i].periodo2Nota =  this.records[i].recuperacaoSemestre1;
              }
            }

            this.records[i].mediaSemestre1 = await CalcularNota.calcMediaSemestral(this.records[i].media1, this.records[i].media2, this.records[i].recuperacaoSemestre1);

            //Terceiro periodo
            if (this.records[i].periodo3_atividades != null) {
              this.records[i].ativPer3= this.records[i].periodo3_atividades[0].atividade;
            }
            else {
              this.records[i].ativPer3 = '';
            }
            if (this.records[i].periodo3_avaliacao != null) {
              this.records[i].avaliacaoPer3 = this.records[i].periodo3_avaliacao.avaliacao;
            }
            else {
              this.records[i].avaliacaoPer3 = '';
            }
            if (this.records[i].periodo3_simulado != null) {
              this.records[i].simuladoPer3 = this.records[i].periodo3_simulado.simulado;
            }
            else {
              this.records[i].simuladoPer3 = '';
            }
            this.records[i].media3 = await CalcularNota.calcTotalPeriodoSemestral(this.records[i], 3,this.configuracao);
            //Quarto periodo
            if (this.records[i].periodo4_atividades != null) {
              this.records[i].ativPer4 = this.records[i].periodo4_atividades[0].atividade;
            }
            else {
              this.records[i].ativPer4 = '';
            }
            if (this.records[i].periodo4_avaliacao != null) {
              this.records[i].avaliacaoPer4 = this.records[i].periodo4_avaliacao.avaliacao;
            }
            else {
              this.records[i].avaliacaoPer4 = '';
            }
            if (this.records[i].periodo4_simulado != null) {
              this.records[i].simuladoPer4 = this.records[i].periodo4_simulado.simulado;
            }
            else {
              this.records[i].simuladoPer4 = '';
            }
            if (this.records[i].periodo4_recuperacao != null) {
              this.records[i].recuperacaoSemestre2 = this.records[i].periodo4_recuperacao.recuperacao;
            }
            else {
              this.records[i].recuperacaoSemestre2 = '';
            }
            this.records[i].media4 = await CalcularNota.calcTotalPeriodoSemestral(this.records[i], 4,this.configuracao);

            this.records[i].periodo3Nota =  this.records[i].media3;
            this.records[i].periodo4Nota =  this.records[i].media4;

            if (this.records[i].recuperacaoSemestre2 != '')  {
              var answer = await CalcularNota.aRecuperacaoSubstituiOQue(this.records[i].periodo3Nota, this.records[i].periodo4Nota, this.records[i].recuperacaoSemestre2);
              if (answer == 1) {
                this.records[i].periodo3Nota  = this.records[i].recuperacaoSemestre2;
                this.records[i].periodo4Nota  = this.records[i].recuperacaoSemestre2;
              }
              else if (answer == 2) {
                this.records[i].periodo3Nota = this.records[i].recuperacaoSemestre2;
              }
              else if (answer == 3) {
                this.records[i].periodo4Nota = this.records[i].recuperacaoSemestre2;
              }
            }
            this.records[i].total = this.records[i].periodo1Nota + this.records[i].periodo2Nota + this.records[i].periodo3Nota + this.records[i].periodo4Nota;

            this.records[i].mediaSemestre2 = await CalcularNota.calcMediaSemestral(this.records[i].media3,  this.records[i].media4,  this.records[i].recuperacaoSemestre2);
            if (this.records[i].aluno_notas_completa_existe) {
              this.records[i].recuperacaoFinal =  this.records[i].aluno_notas_completa.recuperacao_final;
            }
            else {
              this.records[i].recuperacaoFinal  = '';
            }
            this.records[i].mediaFinal = await CalcularNota.caclMediaFinalTipoSemestral(this.records[i].total, this.records[i].recuperacaoFinal, this.tipoTabela);
          }

          let notaCompleta = [];
          //Sempre atualizar a nota completa
          for (var i = 0; i < this.records.length; i++) {
            //Eh um que nao esta na turma
            if (this.records[i].aluno_fora_da_turma == true)
              continue;
            let novo = {
              aluno_notas_id:  this.records[i].aluno_notas_id,
              disciplina_id:  this.records[i].disciplina_id,
              periodo_1:  String(Math.max(this.records[i].periodo1Nota, this.records[i].recuperacaoSemestre1)),
              periodo_2:  String(Math.max(this.records[i].periodo2Nota, this.records[i].recuperacaoSemestre1)),
              periodo_3:  String(Math.max(this.records[i].periodo3Nota, this.records[i].recuperacaoSemestre2)),
              periodo_4:  String(Math.max(this.records[i].periodo4Nota, this.records[i].recuperacaoSemestre2)),
              recuperacao_final: this.records[i].recuperacaoFinal,
              media_final: String(this.records[i].mediaFinal),
              passou: this.records[i].mediaFinal >= this.mediaGeral,
            }
            if (novo.passou == 1) {
              this.records[i].passou = 1;
              novo.passou = 1;
              novo.dependencia = 0;
              //ok
            }
            else if (this.records[i].aluno_notas_completa.dependencia) {
              //nao alterar
              this.records[i].passou = 2;
              novo.passou = 2;
              novo.dependencia = 1;

            }
            else if (!this.records[i].aluno_notas_completa.dependencia) {
              this.records[i].passou = 0;
              novo.passou = 0;
              novo.dependencia = 0;
            }
            notaCompleta.push(novo);
          }
          let data1 = '';
          if (notaCompleta.length)
            data1 = await Nota.adicionarDeTodosAlunos(notaCompleta);
        }
        this.isLoadingPage = false;

      }
      catch(e) {
        /*this.$vaToast.init({
          message: "Erro ao buscar nota dos alunos!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: "danger",
          fullWidth: false,
        })*/
      }
    },

    async verificarPeriodosTurmaDisciplina(){
      let dt = await Nota.GetConfigPeriodo(this.st_turma_id,this.disciplinaUma["disciplina_id"]);
      this.notaconfigperiodo = dt.data;
    },
    async buscarInfoNotaMedia() {
      let data = await Configuracao.buscarConfiguracaoPeloSegmento(this.segmentoInfo);
      this.mediaGeral = data.data.media;
      this.simulado = data.data.simulado;
      this.atividades = data.data.atividades;
      this.avaliacao = data.data.avaliacao;
    },
    async verificarEditarNee(tipo) {
      let novo1 = {
          aluno_id: this.formAlunoComNEE.aluno_id,
          disciplina_id:this.disciplinaUma.disciplina_id,
          ano:this.anoAtual,
          turma_id:this.st_turma_id
      }
      let data1 = await Nota.GetNotaConfig(novo1);
      let novo2 = {
        aluno_id: this.formAlunoComNEE.aluno_id,
        nota_config_id: data1.data.id,
        periodo: this.periodoSelecionado + 1,
        ano: this.formAlunoComNEE.ano,
        tipo: tipo,
      }
      let data2 = await Nee.obtemUmSemId(novo2);
      //cadastrar informacao
      if (data2.data == -1) {
        this.formAlunoComNEE.id = '';
        this.formAlunoComNEE.suporte = '';
        this.formAlunoComNEE.compreensao = '';
        this.formAlunoComNEE.metodologia_utilizada = '';
        this.formAlunoComNEE.diagnostico_pedagogico = '';
        this.formAlunoComNEE.observacao = '';
        this.paraEditarNEE = 0;
      }
      else {
        this.formAlunoComNEE.id = data2.data.id;
        this.formAlunoComNEE.aluno_id = data2.data.aluno_id;
        this.formAlunoComNEE.nota_config_id  = data2.data.nota_config_id;
        this.formAlunoComNEE.periodo = data2.data.periodo;
        this.formAlunoComNEE.suporte = (data2.data.suporte == 0 ? '0' : '1');
        this.formAlunoComNEE.compreensao = (data2.data.compreensao == 0 ? '0' : '1');
        this.formAlunoComNEE.metodologia_utilizada = data2.data.metodologia_utilizada;
        this.formAlunoComNEE.diagnostico_pedagogico = data2.data.diagnostico_pedagogico;
        this.formAlunoComNEE.observacao = data2.data.observacao;
        this.paraEditarNEE = 1;
      }

    },
    async adicionarEditarAtividade(aluno_id_selecionado, nota, qualTipo, idAtividade,  configuracao, periodoSelecionado) {
      if (nota < 0 || nota > configuracao.info.notas.atividades) {
        this.$vaToast.init({
          message: "Digite um valor entre 0 e "+configuracao.info.notas.atividades+"!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'warning'
        })
        this.listarNotas();
        return 0;
      }
      let notaVazia = false;
      if (nota === undefined || nota === null || nota === '') {
        notaVazia = true;
      }

      try {
        //Verificar config
        let novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id:this.disciplinaUma.disciplina_id,
          ano:this.anoAtual,
          turma_id:this.st_turma_id
        }
        let data1 = await Nota.GetNotaConfig(novo1);
        //Atividade diversificada

        let novo2 = {
          id: idAtividade,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          nome: "Atividade Diversificada",
          atividade: nota,
          tipo: qualTipo,
        }

        if (notaVazia == false){
          if (qualTipo == 1) {
            let data2 = await Nota.AddAtividadesComplementares(novo2);
          }
          else {
            let data2 = await Nota.AddAtividadesComplementares(novo2);
          }
          this.listarNotas();
        } else {
          if (qualTipo == 2) {
            let infoApagar = {
              id:novo2.id,
              tipo:2
            }
            let data2 = await Nota.removerNota(infoApagar);
          }
        }
        if (notaVazia == true && qualTipo == 1){
          return false;
        }

        this.listarNotas();

      }
      catch(e) {
        this.$vaToast.init({
          message: "Error ao adicionar atividade",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        })
      }
    },
    async alunocomNEE(cadastrarOuEditar) {
        //Apenas editar
        if (cadastrarOuEditar == 1) {
          try {
            let data1 = await Nee.alterar(this.formAlunoComNEE);
            this.$vaToast.init({
              message: "Editado com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'success'
            })
          }
          catch(e) {
            this.$vaToast.init({
              message: "Problema ao editar!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            })
          }
          this.showModal = !this.showModal;
          return 0;
        }
        try {
          //Verificar config
          let novo1 = {
            aluno_id: this.formAlunoComNEE.aluno_id,
            disciplina_id: this.disciplinaUma.disciplina_id,
            ano:this.anoAtual,
            turma_id:this.st_turma_id
          }
          let data1 = await Nota.GetNotaConfig(novo1);
          //Atividade diversificada

          this.formAlunoComNEE.nota_config_id = data1.data.id;
          this.formAlunoComNEE.periodo =  this.periodoSelecionado + 1;
          let data = await Nee.cadastrar(this.formAlunoComNEE);
          this.$vaToast.init({
              message: "Cadastrado com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'success'
          })
        }
        catch(e) {
          this.$vaToast.init({
              message: "Problema ao cadastrar!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            })
        }
      this.showModal = !this.showModal;
    },
    async adicionarEditarSimulado(aluno_id_selecionado, nota, qualTipo, idSimulado, configuracao, periodoSelecionado) {
      if (nota < 0 || nota > configuracao.info.notas.simulado) {
        this.$vaToast.init({
          message: "Digite um valor entre 0 e "+configuracao.info.notas.simulado+"!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'warning'
        })
        return 0;
      }

      let notaVazia = false;
      if (nota === undefined || nota === null || nota === '') {
        notaVazia = true;
      }

      try {
        //Verificar config
        let novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id:this.disciplinaUma.disciplina_id,
          ano:this.anoAtual,
          turma_id:this.st_turma_id
        }
        let data1 = await Nota.GetNotaConfig(novo1);
        //Atividade diversificada

        let novo2 = {
          id: idSimulado,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          nome: "Simulado",
          simulado: nota,
          tipo: qualTipo,
        }
        if (notaVazia == false){
          if (qualTipo == 1) {
            let data2 = await Nota.AddSimulado(novo2);
          }
          else {
            let data2 = await Nota.AddSimulado(novo2);
          }

        }else {
          if (qualTipo == 2) {
            let infoApagar = {
              id:novo2.id,
              tipo:1
            }
            let data2 = await Nota.removerNota(infoApagar);
          }
        }

        if (notaVazia == true && qualTipo == 1){
          return false;
        }
        this.listarNotas();
      }
      catch(e) {
        this.$vaToast.init({
          message: "Error ao adicionar atividade",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        })
      }
    },
    async adicionarEditarAvaliacao(aluno_id_selecionado, nota, qualTipo, idAvaliacao, configuracao, periodoSelecionado) {
      if (nota < 0 || nota > configuracao.info.notas.avaliacao) {
        this.$vaToast.init({
          message: "Digite um valor entre 0 e "+configuracao.info.notas.avaliacao+"!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'warning'
        })
        this.listarNotas();
        return 0;
      }
      let notaVazia = false;
      if (nota === undefined || nota === null || nota === '') {
        notaVazia = true;
      }
      //Verificar config
      try {
        //Verificar config
        let novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id:this.disciplinaUma.disciplina_id,
          ano:this.anoAtual,
          turma_id:this.st_turma_id
        }
        let data1 = await Nota.GetNotaConfig(novo1);
        let novo2 = {
          id:idAvaliacao,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          avaliacao: nota,
          tipo:qualTipo,
        }
        if(notaVazia == false){
          if (qualTipo == 1) {
            let data2 = await Nota.AddAvaliacao(novo2);
          }
          else {
            let data2 = await Nota.AddAvaliacao(novo2);
          }
        }else {
          if (qualTipo == 2) {
            let infoApagar = {
              id:novo2.id,
              tipo:3
            }
            let data2 = await Nota.removerNota(infoApagar);
          }
        }
        if (notaVazia == true && qualTipo == 1){
          return false;
        }
        this.listarNotas();
      }
      catch(e) {
        this.$vaToast.init({
          message: "Error ao adicionar avalição",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        })
      }
    },
    async adicionarEditarRecuperacao(aluno_id_selecionado, nota, qualTipo, idRecuperacao, configuracao, periodoSelecionado) {
      let recuperacaoSemestral = '';
      //Verificar se eh bimestral ou semestral
      if (configuracao.info.notas.recuperacao_bimestral) {

        if (periodoSelecionado == 1) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b1; //pegar o valor da recuperacao do bimestre 1

        }
        else if (periodoSelecionado == 2) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b2; //pegar o valor da recuperacao do bimestre 2

        }
        else if (periodoSelecionado == 3) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b3; //pegar o valor da recuperacao do bimestre 3

        }
        else if (periodoSelecionado == 4) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_b4; //pegar o valor da recuperacao do bimestre 4
        }
      }
      else {
        if (periodoSelecionado == 2) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_s1; //pegar o valor da recuperacao do semestre 1
        }
        else if(periodoSelecionado == 4) {
          recuperacaoSemestral = configuracao.info.notas.recuperacao_nota_s2;//pegar o valor da recuperacao do semestre 2
        }
      }
      if (nota < 0 || nota > recuperacaoSemestral) {
        this.$vaToast.init({
          message: "Digite um valor entre 0 e "+recuperacaoSemestral+"!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'warning'
        })
        this.listarNotas();
        return 0;
      }
      let notaVazia = false;
      if (nota === undefined || nota === null || nota === '') {
        notaVazia = true;
      }
      try {
        //Verificar config
        let novo1 = {
          aluno_id: aluno_id_selecionado,
          disciplina_id:this.disciplinaUma.disciplina_id,
          ano:this.anoAtual,
          turma_id:this.st_turma_id
        }
        let data1 = await Nota.GetNotaConfig(novo1);
        let novo2 = {
          id:idRecuperacao,
          nota_config_id: data1.data.id,
          periodo: periodoSelecionado,
          recuperacao: nota,
          tipo:qualTipo,
        }
        if (notaVazia == false) {
          let data2 = await Nota.AddRecuperacao(novo2);
        }
        else {
          if (qualTipo == 2) {
            let infoApagar = {
              id:novo2.id,
              tipo:4
            }
            let data2 = await Nota.removerNota(infoApagar);
          }
        }
        if (notaVazia == true && qualTipo == 1){
          return false;
        }
        this.listarNotas();
      }
      catch(e) {
        this.$vaToast.init({
          message: "Error ao adicionar avalição",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        })
      }
    },
    async adicionarEditarRecuperacaoFinal(record, recuperacao, tipo) {
      if (recuperacao < 0 || recuperacao > 100) {
        this.$vaToast.init({
          message: "Digite um valor entre 0 e 100!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'warning'
        })
        this.listarNotas();
        return 0;
      }
      if (recuperacao === undefined || recuperacao === null || recuperacao === '') {
         this.$vaToast.init({
          message: "Informe um valor para a nota!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'warning'
        })
        this.listarNotas();
        return 0;
      }
      var elePassou = Math.max(recuperacao, record.mediaFinal) >= this.mediaGeral;
      let novo2 = {
        aluno_notas_id: record.aluno_notas_id,
        disciplina_id:   this.info.disciplina_id,
        periodo_1:  String(record.media1),
        periodo_2:  String(record.media2),
        periodo_3:  String(record.media3),
        periodo_4:  String(record.media4),
        recuperacao_final: recuperacao,
        media_final: Math.max(recuperacao, record.mediaFinal),
        passou: elePassou,
      }
      if (tipo == 1) {
        let data2 = await Nota.adicionarNotaAlunoDisciplina(novo2);
      }
      else {
        let data2 = await Nota.editarNotaAlunoDisciplina(novo2);
      }
      this.listarNotas();
    },
    limparCampos(){
      this.periodoSelecionado = 0;
      this.notas =[],
      this.segmentoALL = [];
      this.serieALL = [];
      this.turmaALL = [];
      this.turma_id= null;
      this.segmentoInfo = '';
      this.serieInfo = '';
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.buscarSegmentoALL();
    },
    async listarEscolas(){
      const esc = await Escola.obtemTodos();
      this.escolas = esc.data;
    },
    async turma_disciplinas(turma) {
      this.turma = turma;
      this.alunos = [];
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.disciplinaALL = [];
      let data1 = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma);
      for (const el of data1.data) {
        if(el.na_turma == 1){
          let novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplinaALL.push(novo);
        }
      }
    },
    async buscarTipoDeTabela() {
      let data2 = await SegmentoEscolar.obtemUm(this.info.segmento_id);
      this.tipoTabela = data2.data.tipo;
      if (this.tipoTabela == 3) {
        let data3 = await SeriesEscolar.obtemUm(this.info.serie_id);
        if (data3.data.tipo == 1) {
          this.estiloTabelaEJA = 0;
        }
        else {
          this.estiloTabelaEJA = 1;
        }
      }
      return 1;
    },
    async buscarNotasDoAluno() {
      try {
        this.tipoTabela = '';
        this.periodoSelecionado = 0;
        this.info.escola_id = this.escola_id;
        this.info.segmento_id = this.segmentoInfo;
        this.info.serie_id = this.serieInfo;
        this.info.turma_id = this.turma_id;
        this.info.disciplina_id = this.disciplina.id;

        await this.verificarPeriodosTurmaDisciplina();
        await this.buscarTipoDeTabela();
        await this.listarNotas();
      }
      catch(e) {

      }
    },
    async buscarSegmentoALL() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = [];
      for (const el of data.data) {
        if (el.id == 5)
          continue;
        this.segmentoALL.push(el);
      }
    },

    async listaSerie (id) {
      let data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.turma_id = null;
      this.serieInfo = '';
      this.disciplinaALL = [];

      this.buscarConfiguracoes();
      this.buscarInfoNotaMedia();
      this.serieALL = data.data;
    },

    async listaTurmas(){
      let play = {
        'escola':this.escola_id,
        'serie': this.serieInfo,
        'segmento': this.segmentoInfo,
      };
      this.disciplina = '';
      this.info.disciplina_id = null;
      this.turma_id = null;
      this.disciplinaALL = [];
      let data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;
    },

    async listarAlunosdaTurma(){
      let data = await Turma.alunoEmUmaTurma(this.turma);
      this.alunoALL = data.data;
    },

    async visuPeriodosEncerrados(){
      let data = await Nota.VisuPeriodosEncerrar();
      this.encerrado = data.data;
      if(this.encerrado.p1){this.p1 = false;}else{this.p1 = true;}
      if(this.encerrado.p2){this.p2 = false;}else{this.p2 = true;}
      if(this.encerrado.p3){this.p3 = false;}else{this.p3 = true;}
      if(this.encerrado.p4){this.p4 = false;}else{this.p4 = true;}

    },
    async encerrar_reabrir(n){
      try {
           let valor;
          if(n == 1){ if(this.p1 == true){ valor = 1}else{valor = 0}}
          if(n == 2){ if(this.p2 == true){ valor = 1}else{valor = 0}}
          if(n == 3){ if(this.p3 == true){ valor = 1}else{valor = 0}}
          if(n == 4){ if(this.p4 == true){ valor = 1}else{valor = 0}}
          let payload = {
            valor: valor,
            periodo: n,
          }
          let data = await Nota.EncerrarPeriodos(payload);
          this.encerrado = [];
          this.visuPeriodosEncerrados();
          this.limparCampos();
      }
      catch(e) {
        this.$vaToast.init({
            message: "Encerre o(s) período(s) anteriores!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'danger'
        });
        this.visuPeriodosEncerrados();
        this.limparCampos();
      }
    },


    async buscarTurma(id) {
      try{
        const data = await Turma.obtemPorEscola(id);
        this.turmas = data.data;
      }catch(e){

      }
    },
    async buscarDisciplinas(){
      let novo = {
        turma_id: this.st_turma_id,
        professor_id: this.st_professor_id,
      };
      let data = await Turma.obtemTurmaProfessorV2(novo);
      this.disciplina = data.data;
    },

    async Config(disciplina) {
      this.infoVerificarPresenca.disciplina_id = disciplina.disciplina_id;
      this.disciplinaSelecionadaPeloProf = disciplina;
      let data = await Turma.obtemTurmaDisciplina(this.disciplinaSelecionadaPeloProf.turma_disciplina_id);
      this.disciplinaUma = data.data;

      this.tipoTabela = '';
      this.periodoSelecionado = 0;
      this.info.escola_id = this.st_escola_id ;
      this.info.turma_id = this.st_turma_id;
      this.info.disciplina_id = disciplina.disciplina_id;

      await this.verificarPeriodosTurmaDisciplina();
      let dd = await this.buscarTipoDeTabela();
      if(dd)
        await this.listarNotas();

    },

    trocarTurma(){
      this.$router.push({ name: "turmas-Professor" });
    },

    async listarTudo(){
      try{
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);
        const calendario = await Calendario.obtemTodos();
        if(whoiam.data.funcao != 5){
          this.$router.push({ name: "login"});
        }

      }catch(e){

      }
    },


    async mudarAvaliacao(info) {
      this.avaliacao.nota = info.avaliacao;
      this.avaliacao.id = info.id;
      this.realizarEdicaoAvaliacao = !this.realizarEdicaoAvaliacao;
    },
    async mudarAtivadadeComplementar(info) {
      if (this.realizarEdicaoAtividadeComplementar == 0) {
        this.atividadeComplementar.nome = info.nome;
        this.atividadeComplementar.nota = info.atividade;
        this.atividadeComplementar.id = info.id;
      }
      else {
        this.atividadeComplementar.nome = '';
        this.atividadeComplementar.nota = '';
        this.atividadeComplementar.id = '';
      }
      this.realizarEdicaoAtividadeComplementar = !this.realizarEdicaoAtividadeComplementar;
    },

    async encerarPeriodo(periodo){
      try{
        let confirmar = confirm("Deseja Encerrar o Período?, Aviso! Ao encerrar o período o professor não poderá editar as notas de avaliação e atividades.");
        if(confirmar){
          let falso = 0;
          if(periodo == 1){

            this.records.forEach(el => {
              if(el.periodo1_avaliacao == null){
                falso = 1;
              }
            });
            if(falso == 0){
              let novo = {
                    array: this.records,
                    periodo: 1,
              };
              let dt = await Nota.EncerrarPeriodo(novo);
              this.$router.push({name: "notasProfessor"});
            }else{
                alert('Aviso!! Existem Alunos que estão sem nota na Avaliação!');
            }
          }
          if(periodo == 2){
            this.records.forEach(el => {
              if(el.periodo2_avaliacao == null){
                falso = 1;
              }
            });

            if(falso == 0){
              let novo = {
                array: this.records,
                periodo: 2,
              };
              let dt = await Nota.EncerrarPeriodo(novo);
              this.$router.push({name: "notasProfessor"});
            }else{
                alert('Aviso!! Existem Alunos que estão sem nota na Avaliação!');
            }
          }
          if(periodo == 3){
            this.records.forEach(el => {
              if(el.periodo3_avaliacao == null){
                falso = 1;
              }
            });

            if(falso == 0){
              let novo = {
                array: this.records,
                periodo: 3,
              };
              let dt = await Nota.EncerrarPeriodo(novo);
              this.$router.push({name: "notasProfessor"});
            }else{
                alert('Aviso!! Existem Alunos que estão sem nota na Avaliação!');
            }
          }
          if(periodo == 4){
            this.records.forEach(el => {
              if(el.periodo4_avaliacao == null){
                falso = 1;
              }
            });

            if(falso == 0){
              let novo = {
                array: this.records,
                periodo: 4,
              };
              let dt = await Nota.EncerrarPeriodo(novo);
              this.$router.push({name: "notasProfessor"});
            }else{
                alert('Aviso!! Existem Alunos que estão sem nota na Avaliação!');
            }
          }
          if (periodo == 5) {
            let novo = {
              array: this.records,
              periodo: 5
            }
            let dt = await Nota.EncerrarPeriodo(novo);
          }
          await this.verificarPeriodosTurmaDisciplina();
          await this.listarNotas();
        }
      }catch(e){

      }
    },

  },

})
</script>

<style>
  @media(max-width:576px){
    .va-modal__container{
      width:100% !important;
      height:45%;
    }
    .va-modal--mobile-fullscreen {
      min-height: 0vh!important;
    }
  }
</style>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa;
    color: #495057;
    border-right: 1px solid #e9ecef;
    border-bottom: 2px solid #e9ecef;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
    vertical-align: middle;
  }

  .th_esp{
    width:15px;
    background-color: #3f448700;
    border-bottom: 2px solid #fff;
  }
  .cabecario-turma{
    background-color:#4da4e9;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .tbl_situacao_apv{
    font-weight: 600;
    color: #539b53;
  }
  .tbl_situacao_rep{
    font-weight: 600;
    color: #b93b3b;
  }
</style>

<style scoped>

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 23px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .mtwidth{
    width:10%;
  }
  .tdmt{
    text-align: center;font-weight: 800;color: #2b72fd;
  }

  tr td{
    vertical-align: middle;
  }

</style>
